// Import dependencies
import React from "react";
import { useState } from "react";
import { useStaticQuery, graphql, Link, navigate } from "gatsby";

// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import CardsList from "../components/index/cards-list";
import SectionSeparator from "../components/separators/sectionSeparator";
import { PageContainer } from "../styled-components/UILibrary";
import CategoryDrawer from "../components/cateogry-drawer/categoty-drawer";
import SearchBar from "../components/search-bar/searchBar";
import Fuss from "../assets/images/ree_store.png";
import PickupFree from "../assets/images/free_pickeup_index.png";
import JoinTeam from "../assets/images/join_team.png";
import BackgroundImg from "../assets/images/hero_background.png";
import DrawerDynamicContent from "../components/cart/drawer-dynamic";
import IndexTitle from "../components/index/index-title";
import ShopPopup from "../components/shop/shopPopup";

import WhatIsReiniBackground from "../assets/images/what_is_reinin.svg";

import Hp from "../assets/images/hp.png";
import Acer from "../assets/images/acer.png";
import Apple from "../assets/images/apple.png";
import Huawei from "../assets/images/huawei.png";
import Panasonic from "../assets/images/panasonic.png";
import Philips from "../assets/images/philips.png";
import Samsung from "../assets/images/samsung.png";
import Tefal from "../assets/images/tefal.png";
import Xiaomi from "../assets/images/xiaomi.png";


import Uniqlo from "../assets/images/uniqlo.png";
import Forever from "../assets/images/forever21.png";
import Gap from "../assets/images/gap.png";
import Hm from "../assets/images/hm.png";
import Mango from "../assets/images/mango.png";
import Muji from "../assets/images/muji.png";
import Shein from "../assets/images/shein.png";
import Zara from "../assets/images/zara.png";
import Cottonon from "../assets/images/cottonon.png";

import Pearson from "../assets/images/pearson.png";
import Hachette from "../assets/images/hachette.png";
import Harpercollins from "../assets/images/harpercollins.png";
import Wiley from "../assets/images/wiley.png";
import Macmillan from "../assets/images/macmillan.png";
import Penguinrandomhouse from "../assets/images/penguinrandomhouse.png";
import Phaidon from "../assets/images/phaidon.png";
import Simonschuster from "../assets/images/simonschuster.png";
import Taschen from "../assets/images/taschen.png";

import Happycall from "../assets/images/happycall.png";
import Greenlife from "../assets/images/greenlife.png";
import Ikea from "../assets/images/ikea.png";
import Kitchenaid from "../assets/images/kitchenaid.png";
import Scanpan from "../assets/images/scanpan.png";
import Tangs from "../assets/images/tangs.png";
import Tramontina from "../assets/images/tramontina.png";
import Mwf from "../assets/images/mwf.png";
import Cuisinart from "../assets/images/cuisinart.png";

import { InlineShareButtons } from 'sharethis-reactjs';
import { getFirebase } from "../api/firebase";

import { STRIPE_CONSTANT, DEPLOY_TYPE } from "../constants/stripe"

// Import styless
import "./index.css";
import "./shop.css";
// Import from Redux
import { connect } from "react-redux";

/*
    Index page component
*/
const IndexPage = ({ store }) => {

  const [brandDrawerOpen, setBrandDrawerOpen] = useState(false);
  const toggleBrandDrawerOpen = () => {
    setBrandDrawerOpen(!brandDrawerOpen);
  }

  const [shopPopupOpen, setshopPopupOpen] = useState(false);
  const toggleshopPopupOpen = () => {
    setshopPopupOpen(!shopPopupOpen);
  }

  // GraphQL image query
  // GraphQL image query
  const images = useStaticQuery(graphql`
      query {
        electronics: file(relativePath: { eq: "electronics.png" }) {
          childImageSharp {
            fluid(maxWidth: 1344, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        clothes: file(relativePath: { eq: "clothes.png" }) {
          childImageSharp {
            fluid(maxWidth: 1344, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        books: file(relativePath: { eq: "books.png" }) {
          childImageSharp {
            fluid(maxWidth: 1344, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        potsandpans: file(relativePath: { eq: "potsandpans.png" }) {
          childImageSharp {
            fluid(maxWidth: 1344, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        toys: file(relativePath: { eq: "toys.png" }) {
          childImageSharp {
            fluid(maxWidth: 1344, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        currencies: file(relativePath: { eq: "currencies.png" }) {
          childImageSharp {
            fluid(maxWidth: 1344, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        comingsoon: file(relativePath: { eq: "department_coming.png" }) {
          childImageSharp {
            fluid(maxWidth: 1344, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        allbrands: file(relativePath: { eq: "allBrands.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1344, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

      }
  `);

  const brands = Object.values(store.allProductsById)
    .filter((product) => {
      return product.Product.toLowerCase().replace("other ", "")
    })
    .map((product) => {
      if ((product.Department == "Books" || product.Department == "Cookware" || product.Department == "Clothes") && !(product.Product.startsWith("OTHER"))) {
        return product.Product.replace("OTHER ", "").replace(" " + product.SubCategory.toUpperCase(), "")
      } else if (product.Department == "Electronics" && product.Product.startsWith("OTHER") && product.Product != "OTHER " + product.SubCategory.toUpperCase()) {
        return product.Product.replace("OTHER ", "").replace(" " + product.SubCategory.toUpperCase(), "")
      } else {
        return false
      }
    })
    .filter((value, index, self) => {
      return self.indexOf(value) === index && value != "";
    });

  return (
    <Layout isHome>

      <ShopPopup togglePopup={() => toggleshopPopupOpen()} isOpen={shopPopupOpen} value="recycle" />
      <CategoryDrawer />
      <SEO
        title="Home"
        description="On-demand buy-back and recycling for used electronics, books, and clothes."
      />

      <SearchBar HomeSearchPadding={true} />
      <DrawerDynamicContent title="BRANDS" isBrand brandName="BRANDS" arrayContent={brands} showCart={() => toggleBrandDrawerOpen()} styles={brandDrawerOpen ? "cart__open cart__wrapper left_drawer" : "cart__wrapper left_drawer"} />
      {/* Page content */}
      <PageContainer className="home_body_background" style={{
        backgroundImage: "url(" + BackgroundImg + ")",
      }}>
        <div className="shop_mobile_switch" onClick={() => toggleshopPopupOpen()}>
          <span>Recycle</span>
          <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.62 0.790059L6.5 4.67006L10.38 0.790059C10.77 0.400059 11.4 0.400059 11.79 0.790059C12.18 1.18006 12.18 1.81006 11.79 2.20006L7.2 6.79006C6.81 7.18006 6.18 7.18006 5.79 6.79006L1.2 2.20006C0.810001 1.81006 0.810001 1.18006 1.2 0.790059C1.59 0.410059 2.23 0.400059 2.62 0.790059Z" fill="black" />
          </svg>
        </div>
        <div class="hero_container">
          <IndexTitle />
          <p className="index_paragraph">Get paid to recycle almost anything you have lying around the house</p>
          <Link to="how-it-works" className="index_link">
            <span>How it works</span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2" />
            </svg>
          </Link>
        </div>
        <CardsList
          title="Recycle by department"
          cards={[
            ["regular", "Electronics", images.electronics.childImageSharp.fluid, "/galleria/electronics"],
            ["regular", "Clothes", images.clothes.childImageSharp.fluid, "/galleria/clothes"],
            ["regular", "Books", images.books.childImageSharp.fluid, "/galleria/books"],
            ["regular", "Cookware", images.potsandpans.childImageSharp.fluid, "coming soon"],
            ["regular", "Toys", images.toys.childImageSharp.fluid, "coming soon"],
            ["regular", "Currencies", images.currencies.childImageSharp.fluid, "coming soon"],
          ]}
          scrollabe
        />
        <div className="cards-list-container no-padding cards-list-two-info">
          <div>
            <div className="cards-list">
              <div className="cards-list-item cards-list-item-large" style={{ backgroundImage: "url('" + Fuss + "')" }}>
                <div className="card-description">
                  <h2 className="card-title">Ree Store</h2>
                  <p>Use Reinin Coins for discounts on a highly curated range of eco-friendly essentials!</p>
                  <span className="card-comming-soon">COMING SOON</span>
                </div>
              </div>
              <div className="cards-list-item cards-list-item-large" style={{ backgroundImage: "url('" + PickupFree + "')" }}>
                <div className="card-description">
                  <h2 className="card-title">FREE pickups ⚡</h2>
                  <p>Members can book <b>free pickups</b> and <b>get paid within 24-hours</b> after we collect their items</p>
                  <Link to="/pricing" className="recycle-link" style={{ justifyContent: "flex-start" }}>
                    <span>Learn more</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2" />
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="cards-list-item cards-list-item-large" style={{ backgroundImage: "url('" + JoinTeam + "')" }}>
                <div className="card-description">
                  <h2 className="card-title">Join the green team</h2>
                  <p>Get paid to collect items when you want, with your own car — Reinin takes zero commission!</p>
                  <span className="recycle-link" style={{ justifyContent: "flex-start" }} onClick={() => navigate("become-driver")}>
                    <span>Apply</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "30px" }}>
          <SectionSeparator />
        </div>

        <div className="index_challenge_container">
          <div className="index_challenge_head">
            <h1 class="cards-list-title">Recycling challenges</h1>
            <Link to="/reward-center">See all challenges</Link>
          </div>
          <CardsList
            noContainer
            cards={[
              ["challenge", "500", "Get 500 Coins when you recycle at least 5 electronics", "", "reinin"],
              ["challenge", "200", "Get 200 Coins when you recycle at least 20 items of clothing", "", "reinin"],
              ["challenge", "300", "Get 300 Coins when you recycle at least 10 Shein products", "", "shein"],
              ["challenge", "400", "Get 400 Coins when you recycle at least 1 Nespresso product", "", "nespresso"],
              ["challenge", "500", "Get an extra 500 Reinin Coins when you checkout with at least 20 items in your cart", "", "reinin"],
              ["challenge", "500", "Get 500 Coins when you become a Reinin member", "", "reinin"],
            ]}
            length={6}
          />
          <Link to="/reward-center" className="link_only_mobile">See all challenges</Link>
        </div>

        <div className="brand_recycling_container">
          <h1 class="cards-list-title">Brand stores in Electronics</h1>
          <div className="brand_recycling_list">
            <Link to="/brand/hp"> <img src={Hp} /></Link>
            <Link to="/brand/acer"> <img src={Acer} /></Link>
            <Link to="/brand/apple">  <img src={Apple} /></Link>
            <Link to="/brand/huawei"> <img src={Huawei} /></Link>
            <Link to="/brand/panasonic">  <img src={Panasonic} /></Link>
            <Link to="/brand/philips"> <img src={Philips} /></Link>
            <Link to="/brand/samsung">  <img src={Samsung} /></Link>
            <Link to="/brand/tefal">  <img src={Tefal} /></Link>
            <Link to="/brand/xiaomi">  <img src={Xiaomi} /></Link>
            <Link to="/galleria/electronics">
              <div className="brand_recycling_list_link">
                See all
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.5 0L7.09 1.41L12.67 7H0.5V9H12.67L7.09 14.59L8.5 16L16.5 8L8.5 0Z" fill="#1877F2" />
                </svg>
              </div>
            </Link>
          </div>
          <div className="link_only_mobile" onClick={() => navigate("/galleria/electronics")}>See all</div>
        </div>


        <div className="brand_recycling_container">
          <h1 class="cards-list-title">Brand stores in Clothes</h1>
          <div className="brand_recycling_list">
            <Link to="/brand/uniqlo"> <img src={Uniqlo} /></Link>
            <Link to="/brand/forever21"> <img src={Forever} /></Link>
            <Link to="/brand/gap">  <img src={Gap} /></Link>
            <Link to="/brand/hm"> <img src={Hm} /></Link>
            <Link to="/brand/mango">  <img src={Mango} /></Link>
            <Link to="/brand/muji"> <img src={Muji} /></Link>
            <Link to="/brand/shein">  <img src={Shein} /></Link>
            <Link to="/brand/zara">  <img src={Zara} /></Link>
            <Link to="/brand/cottonon">  <img src={Cottonon} /></Link>
            <Link to="/galleria/clothes">
              <div className="brand_recycling_list_link">
                See all
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.5 0L7.09 1.41L12.67 7H0.5V9H12.67L7.09 14.59L8.5 16L16.5 8L8.5 0Z" fill="#1877F2" />
                </svg>
              </div>
            </Link>
          </div>
          <div className="link_only_mobile" onClick={() => navigate("/galleria/clothes")}>See all</div>
        </div>


        <div className="brand_recycling_container">
          <h1 class="cards-list-title">Brand stores in Books</h1>
          <div className="brand_recycling_list">
            <Link to="/brand/pearson"> <img src={Pearson} /></Link>
            <Link to="/brand/hachette"> <img src={Hachette} /></Link>
            <Link to="/brand/harpercollins">  <img src={Harpercollins} /></Link>
            <Link to="/brand/wiley"> <img src={Wiley} /></Link>
            <Link to="/brand/macmillan">  <img src={Macmillan} /></Link>
            <Link to="/brand/penguinrandomhouse"> <img src={Penguinrandomhouse} /></Link>
            <Link to="/brand/phaidon">  <img src={Phaidon} /></Link>
            <Link to="/brand/simonschuster">  <img src={Simonschuster} /></Link>
            <Link to="/brand/taschen">  <img src={Taschen} /></Link>
            <Link to="/galleria/books">
              <div className="brand_recycling_list_link">
                See all
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.5 0L7.09 1.41L12.67 7H0.5V9H12.67L7.09 14.59L8.5 16L16.5 8L8.5 0Z" fill="#1877F2" />
                </svg>
              </div>
            </Link>
          </div>
          <div className="link_only_mobile" onClick={() => navigate("/galleria/books")}>See all</div>
        </div>


        <div className="brand_recycling_container">
          <h1 class="cards-list-title cards-list-title-flex">Brand stores in Cookware<span class="cards-list_coming_soon">COMING SOON</span></h1>
          <div className="brand_recycling_list">
            <a><img src={Happycall} /></a>
            <a> <img src={Greenlife} /></a>
            <a>  <img src={Ikea} /></a>
            <a> <img src={Kitchenaid} /></a>
            <a>  <img src={Scanpan} /></a>
            <a> <img src={Tangs} /></a>
            <a>  <img src={Tramontina} /></a>
            <a>  <img src={Mwf} /></a>
            <a>  <img src={Cuisinart} /></a>
            <div className="brand_recycling_list_link">
              See all
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.5 0L7.09 1.41L12.67 7H0.5V9H12.67L7.09 14.59L8.5 16L16.5 8L8.5 0Z" fill="#1877F2" />
              </svg>
            </div>
          </div>
          <div className="link_only_mobile">See all</div>
        </div>

        <div style={{ backgroundImage: "url('" + WhatIsReiniBackground + "')" }} className="whats_reinin">
          <h3>What is Reinin?</h3>
          <div className="whats_reinin_flex">
            <div className="whats_reinin_item">
              <h4>No-frills buyer</h4>
              <p>We’re not a marketplace – we’re a market maker that instantly buys your recyclables. No haggling, no meetups.</p>
            </div>
            <div className="whats_reinin_item">
              <h4>Simplifier</h4>
              <p>We make recycling easy for people: Instant buy-back quotes, on-demand pickups, and no need to sort stuff!</p>
            </div>
            <div className="whats_reinin_item">
              <h4>Independent</h4>
              <p>Our commitment to ethical recycling practices forms the basis of our commercial partnerships, it’s not just about profit.</p>
            </div>
          </div>
          <span class="whats_reinin_link " onClick={() => navigate("/about")}>
            <span>Learn more about us</span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2"></path></svg>
          </span>
        </div>

        <div className="index_share">
          <span>Share this page with your friends</span>

          <div className="index_share_list">

            <div className="index_share_item">
              <InlineShareButtons
                config={{
                  color: 'social',
                  enabled: true,
                  networks: ['messenger'],
                  url: STRIPE_CONSTANT[DEPLOY_TYPE]["URL"],
                }}
              />
              <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_557_9824)">
                  <path d="M0 23.1479C0 30.4312 3.63333 36.9292 9.31458 41.1729V50L17.8271 45.3292C20.0979 45.9562 22.5042 46.2979 24.9979 46.2979C38.8042 46.2979 49.9979 35.9354 49.9979 23.15C50 10.3646 38.8062 0 25 0C11.1937 0 0 10.3625 0 23.1479H0ZM22.3625 16.6646L28.8833 23.4542L41.1479 16.6646L27.4812 31.1687L21.1167 24.3812L8.69375 31.1708L22.3625 16.6646Z" fill="#2196F3" />
                </g>
                <defs>
                  <clipPath id="clip0_557_9824">
                    <rect width="50" height="50" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>


            <div className="index_share_item">
              <InlineShareButtons
                config={{
                  color: 'social',
                  enabled: true,
                  networks: ['whatsapp'],
                  url: STRIPE_CONSTANT[DEPLOY_TYPE]["URL"],
                }}
              />
              <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_557_9826)">
                  <path d="M42.75 7.18542C38 2.59375 31.75 0 25.0938 0C5.98958 0 -6.00625 20.6979 3.53333 37.1625L0 50L13.1979 46.5583C18.9479 49.6646 23.6583 49.3896 25.1062 49.5729C47.2583 49.5729 58.2854 22.7729 42.7187 7.26667L42.75 7.18542Z" fill="#ECEFF1" />
                  <path d="M25.1396 45.3143L25.1271 45.3122H25.0938C18.4646 45.3122 14.2292 42.1726 13.8125 41.9914L6 44.0226L8.09375 36.4289L7.59583 35.6476C5.53333 32.3643 4.4375 28.583 4.4375 24.6893C4.4375 6.37054 26.8229 -2.78987 39.7771 10.158C52.7 22.9705 43.6271 45.3143 25.1396 45.3143Z" fill="#4CAF50" />
                  <path d="M36.473 29.8065L36.4542 29.9628C35.8271 29.6503 32.773 28.1565 32.2042 27.9503C30.9271 27.4774 31.2875 27.8753 28.8355 30.6836C28.4709 31.0899 28.1084 31.1211 27.4896 30.8399C26.8646 30.5274 24.8584 29.8711 22.4834 27.7461C20.6334 26.0899 19.3917 24.0586 19.025 23.4336C18.4146 22.3794 19.6917 22.2294 20.8542 20.0294C21.0625 19.5919 20.9563 19.2482 20.8021 18.9378C20.6459 18.6253 19.4021 15.5628 18.8813 14.3419C18.3813 13.1253 17.8667 13.2794 17.4813 13.2794C16.2813 13.1753 15.4042 13.1919 14.6313 13.9961C11.2688 17.6919 12.1167 21.5044 14.9938 25.5586C20.648 32.9586 23.6605 34.3211 29.1688 36.2128C30.6563 36.6857 32.0125 36.619 33.0855 36.4649C34.2813 36.2753 36.7667 34.9628 37.2855 33.494C37.8167 32.0253 37.8167 30.8065 37.6605 30.5253C37.5063 30.244 37.098 30.0878 36.473 29.8065Z" fill="#FAFAFA" />
                </g>
                <defs>
                  <clipPath id="clip0_557_9826">
                    <rect width="50" height="50" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div className="index_share_item">
              <InlineShareButtons
                config={{
                  color: 'social',
                  enabled: true,
                  networks: ['wechat'],
                  url: STRIPE_CONSTANT[DEPLOY_TYPE]["URL"],
                }}
              />
              <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_557_9830)">
                  <path d="M42.602 19.9751C29.2937 13.2085 14.1687 25.6314 21.0854 37.0293C24.2958 42.3835 31.7083 45.7647 40.4791 43.2272C42.2333 43.9085 43.7958 45.0126 45.4958 45.8335C45.0562 44.3543 44.5874 42.8918 44.0874 41.4355C53.1499 34.9793 51.1666 24.3251 42.602 19.9751ZM31.9791 27.6585C31.5437 29.023 29.5708 29.4314 28.6145 28.3939C27.5604 27.4335 27.9708 25.4272 29.3541 24.998C30.8833 24.3522 32.6499 26.1251 31.9791 27.6585ZM41.9374 27.8543H41.9666C41.4312 29.0772 39.5895 29.3647 38.6854 28.4168C38.2499 28.0168 38.1229 27.4147 37.9687 26.8897C38.1854 25.9314 38.8437 24.9376 39.9062 24.8918C41.3749 24.6876 42.6895 26.5085 41.9374 27.8543Z" fill="#4CAF50" />
                  <path d="M36.2791 17.1834C35.3708 12.7167 32.2458 8.94379 28.3125 6.76671H28.3687V6.76463C14.4104 -1.12287 -2.96877 9.87921 0.389563 22.8542C1.29165 26.7042 3.95415 29.9313 7.1854 32.0855C6.5604 33.8625 5.96665 35.6396 5.40415 37.4292C7.4354 36.3625 9.46665 35.248 11.5 34.1646C13.9375 34.95 16.5312 35.3334 19.125 35.248C15.9208 26.0563 24.1396 16.3188 36.2791 17.1834ZM23.6187 11.848C25.2541 11.1896 27.1875 12.5667 27.0583 14.3271L27.0625 14.3521C27.0791 16.3625 24.4062 17.6167 22.9062 16.2542C21.35 15.1334 21.7916 12.4105 23.6187 11.848ZM14.4416 14.8625C14.0833 16.6084 11.7521 17.4521 10.3875 16.2792C8.80623 15.1605 9.24998 12.3875 11.1062 11.823C12.925 11.1188 14.9916 12.973 14.4416 14.8625Z" fill="#4CAF50" />
                </g>
                <defs>
                  <clipPath id="clip0_557_9830">
                    <rect width="50" height="50" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div className="index_share_item">
              <InlineShareButtons
                config={{
                  color: 'social',
                  enabled: true,
                  networks: ['telegram'],
                  url: STRIPE_CONSTANT[DEPLOY_TYPE]["URL"],
                }}
              />
              <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z" fill="#039BE5" />
                <path d="M11.4396 24.4583L35.5438 15.1645C36.6626 14.7604 37.6396 15.4374 37.2771 17.1291L37.2792 17.127L33.1751 36.4624C32.8709 37.8333 32.0563 38.1666 30.9167 37.5208L24.6667 32.9145L21.6521 35.8187C21.3188 36.152 21.0376 36.4333 20.3917 36.4333L20.8355 30.0729L32.4188 19.6083C32.923 19.1645 32.3063 18.9145 31.6417 19.3562L17.3271 28.3687L11.1563 26.4437C9.81673 26.0187 9.78756 25.1041 11.4396 24.4583V24.4583Z" fill="white" />
              </svg>
            </div>


          </div>
        </div>

      </PageContainer>
    </Layout>
  );
};


function readProductsList(storeState) {
  let store = {
    allProductsById: storeState.product.productsIndexedById,
  };
  return { store };
}

export default connect(readProductsList)(IndexPage)
