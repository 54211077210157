// Import dependencies
import React from "react"
import anime from "animejs/lib/anime.es.js"
import Img from "gatsby-image"

// Import components
import TitleSection from "../sections/titleSection"
import GeneralButton from "../form/buttons/GeneralButton"

// Import styles
import "../../pages/index.css"
import { ParagraphText } from "../../styled-components/UILibrary"
import "./cards-list.css"

// Import images
import SpfLogo from "../../assets/images/spf-logo.svg"
import PercentBadge from "../../assets/images/percent-badge.svg"
import { Col, Row } from "antd"
import {Link, navigate} from "gatsby"
import {addItemToCart} from "../../redux/actions/cartAction";
import RightArrow from "../../assets/images/arrow_forward.svg"
import Image from '../department/brandImg';

let textIndex = 0

/*
    Hero section for index page
*/
export default class CardsList extends React.Component {
  constructor(props) {
    super(props);
  }
  navigatePage(productIdNav,productNameNav) {
      navigate("/quoteOther", {
          state: {
              productId: productIdNav,
              productName: productNameNav,
              productInfo: false
          },
      });
  }
  render() {
    return (
        <div 
          className={this.props.noContainer ? "cards-list-container cards-list-no-container" : this.props.long ? "cards-list-container cards-list-container-long" : "cards-list-container"}
        >
        { this.props.title
          ? <h1 className="cards-list-title">{ this.props.title }</h1>
          : ''
        }
        <div>
        <div
          className={this.props.scrollabe ? "cards-list cards-list-scrollabe" : "cards-list"}
          style={this.props.length ? {width: "calc((312px * " + this.props.length +") + 48px)"} : {}}
        >
        {this.props.cards.map((cards, i) => {
          if (this.props.cards[i][0] == "regular") {
            return (
              <div className="cards-list-item" key={i} onClick={() => this.props.cards[i][3] != "coming soon" && navigate(this.props.cards[i][3])} style={{cursor: "pointer"}}>
                <h2 className="card-title">{ this.props.cards[i][1] }</h2>
                <div className="card-image-container"><Img fluid={this.props.cards[i][2]}/></div>
                { this.props.cards[i][3] != "coming soon" ?
                  <p className="recycle-link">
                    <span>Recycle now</span>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2"/>
                    </svg>
                  </p>
                  :
                  <span className="cards-list_coming_soon">COMING SOON</span>
                }
              </div>
            );
          } else if (this.props.cards[i][0] == "all") {
            return (
              <div
                className="cards-list-item cards-list-item-wrap"
                style={{cursor: "pointer"}}
                onClick={() => this.props.toggleBrandDrawerOpen()}
              >
                <h2 className="card-title">All brands</h2>
                <Img fluid={this.props.cards[i][1]}/>
                <p className="recycle-link">See All</p>
              </div>
            );
          } else if (this.props.cards[i][0] == "challenge") {
            return (
              <div
                className="cards-list-item cards-list-item-wrap card-list-challenge"
                style={{cursor: "pointer"}}
              >
                <div className="card-list-challenge-award"><span><span>{this.props.cards[i][1]}</span>REININ<br />COINS</span></div>
                <div className="card-list-challenge-information">
                  <span>CHALLENGE</span>
                  <p>{this.props.cards[i][2]}</p>
                  {
                    this.props.cards[i][4] == "reinin" ? 
                    <svg width="55" height="12" viewBox="0 0 55 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1002 1.90548C4.11096 0.922496 5.21225 0.287335 6.40405 0L6.65297 3.87902C6.26073 3.93951 5.78552 4.08318 5.22734 4.31002C4.66915 4.52174 4.25428 4.7259 3.98273 4.9225V11.6824H0V0.340266H1.96874L3.1002 1.90548Z" fill="#0AAE67"/>
                      <path d="M13.0192 9.05104C14.3015 9.05104 15.6366 8.85444 17.0246 8.46125L17.5224 11.2514C16.0289 11.7505 14.3845 12 12.5892 12C10.5979 12 9.16469 11.5388 8.2897 10.6163C7.4147 9.67864 6.97721 8.14367 6.97721 6.01134C6.97721 3.89414 7.42225 2.36673 8.31233 1.42911C9.21749 0.491494 10.6733 0.0226859 12.6798 0.0226859C14.0224 0.0226859 15.0558 0.136107 15.78 0.362949C16.5192 0.589792 17.0396 0.967864 17.3414 1.49716C17.6582 2.02647 17.8166 2.78261 17.8166 3.7656C17.8166 4.73346 17.6205 5.48204 17.2282 6.01134C16.836 6.54064 16.1722 6.91872 15.2369 7.14556C14.3015 7.3724 12.9739 7.48582 11.2541 7.48582V8.91493C11.6765 9.00567 12.2649 9.05104 13.0192 9.05104ZM12.8834 2.97164C12.2649 2.97164 11.8349 3.0397 11.5936 3.1758C11.3673 3.31191 11.2541 3.569 11.2541 3.94707V5.39887H12.5892C13.2078 5.39887 13.6302 5.33081 13.8565 5.19471C14.0978 5.04348 14.2185 4.77127 14.2185 4.37807V2.97164H12.8834Z" fill="#0AAE67"/>
                      <path d="M23.5525 0.294896V11.6824H19.5698V0.816635L23.5525 0.294896Z" fill="#0AAE67"/>
                      <path d="M25.5648 0.340266H27.5335L28.5518 1.47448C29.804 0.506617 31.2824 0.0226859 32.9871 0.0226859C34.0432 0.0226859 34.8653 0.241967 35.4537 0.68053C36.0421 1.10397 36.3362 1.70132 36.3362 2.47259V11.6824H32.3535V3.31191H30.679C30.2565 3.31191 29.9624 3.3724 29.7964 3.49338C29.6305 3.61437 29.5475 3.81853 29.5475 4.10586V11.6824H25.5648V0.340266Z" fill="#0AAE67"/>
                      <path d="M42.2163 0.294896V11.6824H38.2336V0.816635L42.2163 0.294896Z" fill="#0AAE67"/>
                      <path d="M44.2285 0.340266H46.1973L47.2156 1.47448C48.4677 0.506617 49.9462 0.0226859 51.6509 0.0226859C52.7069 0.0226859 53.5291 0.241967 54.1175 0.68053C54.7058 1.10397 55 1.70132 55 2.47259V11.6824H51.0173V3.31191H49.3427C48.9203 3.31191 48.6261 3.3724 48.4602 3.49338C48.2942 3.61437 48.2113 3.81853 48.2113 4.10586V11.6824H44.2285V0.340266Z" fill="#0AAE67"/>
                    </svg>
                    :          <Image filename={this.props.cards[i][4] + "_logo.png"} imgtype="challenge"/>
                  }
                </div>
              </div>
            );
          } else if (this.props.cards[i][0] == "challenge_reward") {
            return (
              <div
                className="cards-list-item cards-list-item-wrap card-list-challenge card-rewards-redeemed"
                style={{cursor: "pointer"}}
              >
                <div className="card-list-challenge-award"><span><span>{this.props.cards[i][1]}</span>REININ<br />COINS</span></div>
                <div className="card-list-challenge-information">
                  <span>CHALLENGE</span>
                  <p>{this.props.cards[i][2]}</p>
                  {
                    this.props.cards[i][4] == "reinin" ? 
                    <svg width="55" height="12" viewBox="0 0 55 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1002 1.90548C4.11096 0.922496 5.21225 0.287335 6.40405 0L6.65297 3.87902C6.26073 3.93951 5.78552 4.08318 5.22734 4.31002C4.66915 4.52174 4.25428 4.7259 3.98273 4.9225V11.6824H0V0.340266H1.96874L3.1002 1.90548Z" fill="#0AAE67"/>
                      <path d="M13.0192 9.05104C14.3015 9.05104 15.6366 8.85444 17.0246 8.46125L17.5224 11.2514C16.0289 11.7505 14.3845 12 12.5892 12C10.5979 12 9.16469 11.5388 8.2897 10.6163C7.4147 9.67864 6.97721 8.14367 6.97721 6.01134C6.97721 3.89414 7.42225 2.36673 8.31233 1.42911C9.21749 0.491494 10.6733 0.0226859 12.6798 0.0226859C14.0224 0.0226859 15.0558 0.136107 15.78 0.362949C16.5192 0.589792 17.0396 0.967864 17.3414 1.49716C17.6582 2.02647 17.8166 2.78261 17.8166 3.7656C17.8166 4.73346 17.6205 5.48204 17.2282 6.01134C16.836 6.54064 16.1722 6.91872 15.2369 7.14556C14.3015 7.3724 12.9739 7.48582 11.2541 7.48582V8.91493C11.6765 9.00567 12.2649 9.05104 13.0192 9.05104ZM12.8834 2.97164C12.2649 2.97164 11.8349 3.0397 11.5936 3.1758C11.3673 3.31191 11.2541 3.569 11.2541 3.94707V5.39887H12.5892C13.2078 5.39887 13.6302 5.33081 13.8565 5.19471C14.0978 5.04348 14.2185 4.77127 14.2185 4.37807V2.97164H12.8834Z" fill="#0AAE67"/>
                      <path d="M23.5525 0.294896V11.6824H19.5698V0.816635L23.5525 0.294896Z" fill="#0AAE67"/>
                      <path d="M25.5648 0.340266H27.5335L28.5518 1.47448C29.804 0.506617 31.2824 0.0226859 32.9871 0.0226859C34.0432 0.0226859 34.8653 0.241967 35.4537 0.68053C36.0421 1.10397 36.3362 1.70132 36.3362 2.47259V11.6824H32.3535V3.31191H30.679C30.2565 3.31191 29.9624 3.3724 29.7964 3.49338C29.6305 3.61437 29.5475 3.81853 29.5475 4.10586V11.6824H25.5648V0.340266Z" fill="#0AAE67"/>
                      <path d="M42.2163 0.294896V11.6824H38.2336V0.816635L42.2163 0.294896Z" fill="#0AAE67"/>
                      <path d="M44.2285 0.340266H46.1973L47.2156 1.47448C48.4677 0.506617 49.9462 0.0226859 51.6509 0.0226859C52.7069 0.0226859 53.5291 0.241967 54.1175 0.68053C54.7058 1.10397 55 1.70132 55 2.47259V11.6824H51.0173V3.31191H49.3427C48.9203 3.31191 48.6261 3.3724 48.4602 3.49338C48.2942 3.61437 48.2113 3.81853 48.2113 4.10586V11.6824H44.2285V0.340266Z" fill="#0AAE67"/>
                    </svg>
                    :          <Image filename={this.props.cards[i][4] + "_logo.png"} imgtype="challenge"/>
                  }
                </div>
              </div>
            );
          }
        })}
        </div>
        </div>
      </div>
    )
  }
}
