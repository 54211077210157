// Import dependencies
import React from "react";

class IndexTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleArray: ["that new phone", "your dream camera", "a fresh wardrobe", "the next great read", "a better vacuum", "tomorrow",],
      titleIndex: 0
    };
  }
  
  componentDidMount() {
    this.interval = setInterval(() => {
      if (this.state.titleIndex == 5) {
        this.setState({ titleIndex: 0 });
      } else {
        this.setState({ titleIndex: this.state.titleIndex+1 });
      }
    }, 4000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  
  render() {
    return(
      //
      <h1 className="index__title">Make room for
        <div className="index__title-change">
          <span className="title-word-change-container">
            {
              this.state.titleArray.map((word, i) => {
                return(
                  <span className={
                    this.state.titleIndex == i
                    ? "title-word-change active"
                    : "title-word-change"
                  }>
                    <span className="title-word">{word}</span>
                    <span className="title-word-border"></span>
                  </span>
                );
              })
            }
          </span>
        </div>
      </h1>
    );
  }

}

export default IndexTitle;
