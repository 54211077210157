// Import dependencies
import React from "react";
import { navigate } from "gatsby";
// Import styless
import "./shop-popup.css";


/*
    Index page component
*/
class ShopPopup extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        selected: this.props.value,
      };
      this.confirmSelection = this.confirmSelection.bind(this);
    }

    confirmSelection = () => {
        if (this.state.selected == this.props.value) {
            this.props.togglePopup();
        } else {
            if (this.state.selected == "recycle") navigate("/");
            if (this.state.selected == "shop") navigate("/shop");
        }
    }

    render() {
      return (
        <div className="popup_background_shop" style={this.props.isOpen ? {} : {"display": "none"}}>
            <div className="popup_shop_empty" onClick={() => this.props.togglePopup()}></div>
            <div className="shop_popup_container">
                <div className="shop_popup_card">
                    <p>I WANT TO...</p>
                </div>
                <div className="shop_popup_card" onClick={() => this.setState({selected: "recycle"})}>
                    <span>Recycle</span>
                    <svg style={this.state.selected == "recycle" ? {} : {"display": "none"}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z" fill="#1877F2"/>
                    </svg>
                </div>
                <div className="shop_popup_card" onClick={() => this.setState({selected: "shop"})}>
                    <span>Shop</span>
                    <svg style={this.state.selected == "shop" ? {} : {"display": "none"}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z" fill="#1877F2"/>
                    </svg>
                </div>
                <div className="shop_popup_card">
                    <div className="shop_btn" onClick={() => this.confirmSelection()}>Confirm</div>
                </div>
            </div>
        </div>
    );
  }
}

export default ShopPopup;
